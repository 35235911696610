<div class="card">
  <div class="card-header">
    <div class="card-title">
      <b>{{ header }}</b>
    </div>
  </div>
  <div class="card-body">
    <canvas #bubbleChartCanvas
      baseChart
      [datasets]="bubbleChartData"
      [options]="bubbleChartOptions"
      [colors]="bubbleChartColors"
      [chartType]="bubbleChartType">
    </canvas>
  </div>
</div>
