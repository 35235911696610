import { Component, Input } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color, Label, monkeyPatchChartJsTooltip, monkeyPatchChartJsLegend } from 'ng2-charts';

@Component({
  selector: 'app-radar-chart',
  templateUrl: './radar-chart.component.html',
  styleUrls: ['./radar-chart.component.css']
})
export class RadarChartComponent {

  @Input() header = "Radar Chart";
  @Input() data = [];

  radarChartOptions: ChartOptions;
  radarChartLabels: Label[];
  radarChartData: ChartDataSets[];
  radarChartType: ChartType;
  radarChartLegend: boolean;
  radarChartColors: Color[];

  constructor() {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnChanges() {
    this.radarChartOptions = this.createOptions();
    let datasets = [];
    let l = [];
    this.data.map((dataset) => {
      l = dataset.data.map((point) => String(point.key));
      datasets.push({
        data: dataset.data.map((point) => point.count),
        label: dataset.label,
        fill: true,
        pointRadius: 5,
      });
    });
    this.radarChartData = datasets;
    this.radarChartLabels = l;
    this.radarChartType = 'radar';
    this.radarChartLegend = true;
  }

  private createOptions(): ChartOptions {
    return {
      responsive: true,
      scale: {
        angleLines: {
          display: true,
          color: 'rgba(0, 0, 0, 0.1)',
        },
        ticks: {
          beginAtZero: true,
          stepSize: 10,
        },
      },
      elements: {
        arc: {
          borderWidth: 0,
        },
      },
      tooltips: {
        enabled: true,
        callbacks: {
          title: (tooltipItems, data) => {
            if (tooltipItems && tooltipItems.length > 0) {
              const dataIndex = tooltipItems[0].index;
              const datasetIndex = tooltipItems[0].datasetIndex;
              const label = data.datasets[datasetIndex].label;
              const month = this.radarChartLabels[dataIndex];
              return `${month} - ${label}`;
            }
            return '';
          },
          label: function (tooltipItem, data) {
            let count = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            return `Number of cases: ${count}`;
          },
        },
      },
      plugins: {
        datalabels: {
          display: false,
          color: '#fff',
          align: 'center',
          anchor: 'center',
          font: {
            size: 14,
            weight: 'bold',
          }
        }
      },
      maintainAspectRatio: true,
      legend: {
        position: 'right',
        fullWidth: true,
        labels: {
          fontSize: 12,
          padding: 5,
          usePointStyle: true
        }
      },
    };
  }
}
