import { BrowserModule } from '@angular/platform-browser';

/* Routing */
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgSelectModule } from '@ng-select/ng-select';

/* Components */
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// rich grid
import { APP_BASE_HREF } from '@angular/common';
import { DatePipe } from '@angular/common';
import { AppHttpInterceptor } from './modules/shared/interceptor/http.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './modules/shared/shared.module';
import { AuthorizationComponent } from './modules/authorization/authorization.component';
import { LoginComponent } from './modules/login/login.component';
import { PageNotFoundComponent } from './modules/page-not-found/page-not-found.component';


@NgModule({
  declarations: [
    AppComponent,
    AuthorizationComponent,
    LoginComponent,
    PageNotFoundComponent,
   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    NgxSpinnerModule,
    SharedModule,
    NgSelectModule
  ],
  exports: [
    NgxSpinnerModule,
    SharedModule
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true }, 
    AppComponent, 
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
// platformBrowserDynamic().bootstrapModule(AppModule);
