import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, RoutesRecognized } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from 'src/app/modules/authorization/authorization.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private router: Router,
        private AuthorizationService: AuthorizationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.isAuthenticated(route);
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.isAuthenticated(childRoute);
    }

    protected isAuthenticated(route?: ActivatedRouteSnapshot) {
        this.AuthorizationService.LastActive();
        const currentUser = this.AuthorizationService.currentUserValue;
        const current_role = this.AuthorizationService.currentUserRole;
        const token = this.AuthorizationService.currentToken;
        const currentTenant = this.AuthorizationService.currentTenant;
        let response = false;
        const defaultRoutes = ['dashboard']
        if (currentUser && current_role && token && currentTenant) {
            const action = route.data.action;
            const module = route.data.module;
            if (String(current_role) == "0" || defaultRoutes.indexOf(action) > -1) {
                response = true;
            } else if (route.data && Number(current_role) > 0 && String(Number(current_role)) == current_role) {
                const currentRolePermissions = JSON.parse(this.AuthorizationService.currentUserRolePermissions) || [];
                const permission = currentRolePermissions.filter((res: any) => {
                    if ((res.action == action) &&
                        (res.module == module) &&
                        (action != undefined && module != undefined)) {
                        return res;
                    }
                });

                if ((permission.length > 0)) {
                    response = true;
                }
            }

        } else {
            this.AuthorizationService.LogOut();
        }

        // not logged in so redirect to login page with the return url
        if (response == false) {
            this.router.navigate(['/']);
        }
        return response;
    }
}