import { Component, Input, OnChanges } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

interface BubbleChartDataItem {
  label: string;
  value: number;
  radius: number;
}

@Component({
  selector: 'app-bubble-chart',
  templateUrl: './bubble-chart.component.html',
  styleUrls: ['./bubble-chart.component.css']
})

export class BubbleChartComponent implements OnChanges {
  @Input('header') header = 'Bubble Chart';
  @Input('data') data: BubbleChartDataItem[] = [];

  bubbleChartOptions: ChartOptions;
  bubbleChartData: ChartDataSets[];
  bubbleChartType: ChartType = 'bubble';
  bubbleChartColors: Color[];
  bubbleChartPlugins = [];

  constructor() {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnChanges() {
    const dataPoints = this.data.map(item => ({
      x: Math.random() * 10,
      y: item.value,
      r: this.calculateBubbleRadius(item.value),
      label: item.value,
    }));
  
    this.bubbleChartData = [
      {
        data: dataPoints,
      },
    ];

    this.bubbleChartColors = [
      {
        backgroundColor: [
          'rgba(34,117,8,0.6)',
          'rgba(228,52,8,0.6)', 
          'rgba(52,20,196,0.6)', 
          'rgba(249,139,44,0.6)',
          'rgba(32,201,151,0.6)',
          'rgba(58, 163, 78, 0.6)',
          'rgba(155, 120, 23, 0.6)',
          'rgba(166, 125, 184, 0.6)',
          'rgba(87, 153, 51, 0.6)',
          'rgba(145, 90, 140, 0.6)',
          'rgba(255, 77, 77, 0.6)',
          'rgba(93, 177, 255, 0.6)',
          'rgba(155, 114, 0, 0.6)',
          'rgba(109, 208, 235, 0.6)',
          'rgba(172, 240, 242, 0.6)',
        ],
      }
    ];
    this.bubbleChartPlugins = [pluginDataLabels];
    this.bubbleChartOptions = this.createOptions();
  }

  private calculateBubbleRadius(value: number): number {
    const minRadius = 10;
    const maxRadius = 25;
    
    const maxValue = Math.max(...this.data.map(item => item.value));
    const scalingFactor = (maxRadius - minRadius) / maxValue;
    
    return Math.max(value * scalingFactor + minRadius, minRadius);
  }

  private createOptions(): ChartOptions {
    
    return {
      responsive: true,
      tooltips: {
        enabled: true,
        callbacks: {
          title: (tooltipItem) => {
            if (tooltipItem && tooltipItem.length > 0) {
              return `Report Type: ${this.data[tooltipItem[0].index].label}`;
            }
            return '';
          },
          label: (tooltipItem) => {
            if (tooltipItem) {
              const dataIndex = tooltipItem.index;
              const dataItem = this.data[dataIndex];
              const value = dataItem.value;
              return `Number of Cases: ${value}`;
            }
            return '';
          },
        },
      },
      scales: {
        xAxes: [
          {
            type: 'linear',
            position: 'bottom',
            ticks: {
              display: false,
              stepSize: 5,
            },
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              display: false,
              stepSize: 5,
            },
            gridLines: {
              display: false,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      plugins: {
        datalabels: {
          display: true,
          color: '#fff',
          align: 'center',
          anchor: 'center',
          font: {
            size: 14,
            weight: 'bold',
          },
          textShadowBlur: 10,
          textShadowColor: 'rgba(0, 0, 0, 0.75)',
        }
      }
    }
  }
}
