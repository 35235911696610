<div id="contentWrapper">
    <div class="image-container"></div>
    <div id="content">
        <div class="logo-container">
            <!-- <img class='logoImage1' id='companyLogo' src='assets/logo_login.png' alt='Datacreds' /> -->
            <img class='logoImage2' id='companyLogo' src='assets/salvus_logo.png' alt='Salvus' />
        </div>
        <div id="authArea" class="groupMargin" *ngIf="page=='login'">
          <div id="loginArea">
            <form [formGroup]="loginForm" id="loginForm" autocomplete="off" (ngSubmit)="onSubmit()" >
                <div class="form-group">
                    <input (blur)="requestRolesList()"  [ngClass]="{ 'is-invalid': submitted && f.customerName.errors }" id="customerNameInput" formControlName="customerName" type="text" value="" tabindex="1" class="form-control"
                        spellcheck="false"  placeholder="Customer ID" autocomplete="off"/>
                </div>
                <div class="form-group">
                    <input [ngClass]="{ 'is-invalid': submitted && f.UserName.errors }" id="userNameInput" formControlName="UserName"  type="email" value="" tabindex="1" class="form-control"
                    spellcheck="false" placeholder="someone@example.com" autocomplete="off" minlength='5' maxlength="50"/>
                </div>
                <div class="form-group">
                    <input  [ngClass]="{ 'is-invalid': submitted && f.Password.errors }" id="passwordInput" formControlName="Password" type="password" tabindex="2" class="form-control"
                    placeholder="Password" autocomplete="off"/>
                </div>
                <div class="form-group">
                    <ng-select  class="ng-select.custom" (ngModelChange)="showConfirm($event)" formControlName="customerRole" [items]="roles" bindLabel="role_name" bindValue="role_id" placeholder="Select Role">
                    </ng-select>
                    
                </div>
                <div id="submissionArea" class="submitMargin">
                    <button id="submitButton"  type="submit" class="sign-in-button" tabindex="4" role="button">Sign in</button>
                </div>
            </form>
            <div class="mt-4 mb-4">
                <button class="update-password-button" (click)="page='updatepassword'">Update Password</button>
                <button class="forgot-password-button" (click)="page='forgotpassword'">Forgot Password</button> 
            </div>
          </div>
        </div>
        <div id="authArea" class="groupMargin" *ngIf="page=='forgotpassword'">
            <div id="loginArea">
              <form [formGroup]="forgotPasswordForm" id="forgotPasswordForm" autocomplete="off" (ngSubmit)="onSubmitPassword()" >
                <div class="form-group">
                    <input (blur)="setCustomerName()"  [ngClass]="{ 'is-invalid': submitted && g.customerName.errors }" id="customerNameInput" formControlName="customerName" type="text" value="" tabindex="1" class="form-control"
                        spellcheck="false"  placeholder="Customer ID" autocomplete="off"/>
                </div>
                  <div class="form-group">
                      <input [ngClass]="{ 'is-invalid': submitted && g.UserName.errors }" id="userNameInput" formControlName="UserName"  type="email" value="" tabindex="1" class="form-control"
                      spellcheck="false" placeholder="someone@example.com" autocomplete="off" minlength='5' maxlength="50"/>
                  </div>
                  <div class="form-group" *ngIf="isOtpSent">
                    <div class="input-with-icon">
                      <input  [ngClass]="{ 'is-invalid': submitted && g.Password.errors }" id="passwordInput" formControlName="Password" [type]="showFPass ? 'text' : 'password'" tabindex="2" class="form-control"
                      placeholder="Password" autocomplete="off" minlength='8'/>
                      <i (click)="showFPass=!showFPass" [class]="showFPass ? 'lnr icon-eye3 fs3' : 'lnr icon-eye-hidden2 fs3'"></i>
                    </div>
                  </div>
                  <div class="form-group" *ngIf="isOtpSent">
                    <div class="input-with-icon">
                      <input  [ngClass]="{ 'is-invalid': submitted && g.ConfirmPassword.errors }" id="passwordInput" formControlName="ConfirmPassword" [type]="showFCPass ? 'text' : 'password'" tabindex="2" class="form-control"
                      placeholder="Confirm Password" autocomplete="off"/>
                      <i (click)="showFCPass=!showFCPass" [class]="showFCPass ? 'lnr icon-eye3 fs3' : 'lnr icon-eye-hidden2 fs3'"></i>
                    </div>
                  </div>
                  <div class="form-group" *ngIf="isOtpSent">
                      <input  [ngClass]="{ 'is-invalid': submitted && g.OTP.errors }" id="OTPInput" formControlName="OTP" type="text" tabindex="2" class="form-control"
                      placeholder="Enter OTP" autocomplete="off"/>
                  </div>
                 <div class="text-sm" *ngIf="forgotPasswordFormField?.dirty">
                    <span class="block" [ngClass]="forgotPasswordFormField?.value?.match('^(?=.*[A-Z])') ? 'text-green-400' : 'text-red-400'">
                        {{ forgotPasswordFormField?.value?.match('^(?=.*[A-Z])') ? '✔' : '✖' }} Password must contain an uppercase letter</span>
                    <span class="block" [ngClass]="forgotPasswordFormField?.value?.match('(?=.*[a-z])') ? 'text-green-400' : 'text-red-400'">
                        {{ forgotPasswordFormField?.value?.match('(?=.*[a-z])') ? '✔' : '✖' }} Password must contain a lowercase letter</span>
                    <span class="block" [ngClass]="forgotPasswordFormField?.value?.match('(.*[0-9].*)') ? 'text-green-400' : 'text-red-400'">
                        {{ forgotPasswordFormField?.value?.match('(.*[0-9].*)') ? '✔' : '✖' }} Password must contain a number</span>
                    <span class="block" [ngClass]="forgotPasswordFormField?.value?.match('(?=.*[!@#$%^&*])') ? 'text-green-400' : 'text-red-400'">
                        {{ forgotPasswordFormField?.value?.match('(?=.*[!@#$%^&*])') ? '✔' : '✖' }} Password must contain a special character</span>
                    <span class="block" [ngClass]="forgotPasswordFormField?.value?.match('.{8,}') ? 'text-green-400' : 'text-red-400'">
                        {{ forgotPasswordFormField?.value?.match('.{8,}') ? '✔' : '✖' }} Password must contain at least 8 characters</span>
                 </div>
                  <div id="submissionArea" class="submitMargin" *ngIf="isOtpSent">
                      <button id="submitButton"  type="submit" class="sign-in-button" tabindex="4" role="button">Submit</button>
                  </div>
                  <div id="submissionArea" class="submitMargin" *ngIf="!isOtpSent">
                      <button id="submitButton"  (click)="requestOTP()" class="sign-in-button" tabindex="4" role="button">Send OTP</button>
                  </div>
              </form>
              <div class="mt-4 mb-4">
                  <button class="update-password-button" (click)="page='login'">Login Page</button> 
                  <button class="forgot-password-button" (click)="page='updatepassword'">Update Password</button>
              </div>
            </div>
        </div>
        <div id="authArea" class="groupMargin" *ngIf="page=='updatepassword'">
            <div id="loginArea">
              <form [formGroup]="updatePasswordForm" id="updatePasswordForm" autocomplete="off" (ngSubmit)="onUpdatePassword()" >
                <div class="form-group">
                    <input (blur)="setCustomerNameForUpdate()"  [ngClass]="{ 'is-invalid': submitted && u.customerName.errors }" id="customerNameInput" formControlName="customerName" type="text" value="" tabindex="1" class="form-control"
                        spellcheck="false"  placeholder="Customer ID" autocomplete="off"/>
                </div>
                  <div class="form-group">
                      <input [ngClass]="{ 'is-invalid': submitted && u.UserName.errors }" id="userNameInput" formControlName="UserName"  type="email" value="" tabindex="1" class="form-control"
                      spellcheck="false" placeholder="someone@example.com" autocomplete="off" minlength='5' maxlength="50"/>
                  </div>
                  <div class="form-group">
                    <div class="input-with-icon">
                        <input  [ngClass]="{ 'is-invalid': submitted && u.OldPassword.errors }" id="passwordInput" formControlName="OldPassword" [type]="showOPass ? 'text' : 'password'" tabindex="2" class="form-control"
                            placeholder="Old Password" autocomplete="off"/>
                        <i (click)="showOPass=!showOPass" [class]="showOPass ? 'lnr icon-eye3 fs3' : 'lnr icon-eye-hidden2 fs3'"></i>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-with-icon">
                      <input  [ngClass]="{ 'is-invalid': submitted && u.Password.errors }" id="passwordInput" formControlName="Password" [type]="showPass ? 'text' : 'password'" tabindex="2" class="form-control"
                        placeholder="Password" autocomplete="off" minlength='8'/>
                      <i (click)="showPass=!showPass" [class]="showPass ? 'lnr icon-eye3 fs3' : 'lnr icon-eye-hidden2 fs3'"></i>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-with-icon">
                      <input  [ngClass]="{ 'is-invalid': submitted && u.ConfirmPassword.errors }" id="passwordInput" formControlName="ConfirmPassword" [type]="showCPass ? 'text' : 'password'" tabindex="2" class="form-control"
                        placeholder="Confirm Password" autocomplete="off"/>
                      <i (click)="showCPass=!showCPass" [class]="showCPass ? 'lnr icon-eye3 fs3' : 'lnr icon-eye-hidden2 fs3'"></i>
                    </div>
                  </div>
                 <div class="text-sm" *ngIf="updatePasswordFormField?.dirty">
                    <span class="block" [ngClass]="updatePasswordFormField?.value?.match('^(?=.*[A-Z])') ? 'text-green-400' : 'text-red-400'">
                        {{ updatePasswordFormField?.value?.match('^(?=.*[A-Z])') ? '✔' : '✖' }} Password must contain an uppercase letter</span>
                    <span class="block" [ngClass]="updatePasswordFormField?.value?.match('(?=.*[a-z])') ? 'text-green-400' : 'text-red-400'">
                        {{ updatePasswordFormField?.value?.match('(?=.*[a-z])') ? '✔' : '✖' }} Password must contain a lowercase letter</span>
                    <span class="block" [ngClass]="updatePasswordFormField?.value?.match('(.*[0-9].*)') ? 'text-green-400' : 'text-red-400'">
                        {{ updatePasswordFormField?.value?.match('(.*[0-9].*)') ? '✔' : '✖' }} Password must contain a number</span>
                    <span class="block" [ngClass]="updatePasswordFormField?.value?.match('(?=.*[!@#$%^&*])') ? 'text-green-400' : 'text-red-400'">
                        {{ updatePasswordFormField?.value?.match('(?=.*[!@#$%^&*])') ? '✔' : '✖' }} Password must contain a special character</span>
                    <span class="block" [ngClass]="updatePasswordFormField?.value?.match('.{8,}') ? 'text-green-400' : 'text-red-400'">
                        {{ updatePasswordFormField?.value?.match('.{8,}') ? '✔' : '✖' }} Password must contain at least 8 characters</span>
                 </div>
                  <div id="submissionArea" class="submitMargin">
                      <button id="submitButton"  type="submit" class="sign-in-button" tabindex="4" role="button">Submit</button>
                  </div>
              </form>
              <div class="mt-4 mb-4">
                  <button class="update-password-button" (click)="page='login'">Login Page</button> 
                  <button class="forgot-password-button" (click)="page='forgotpassword'">Forgot Password</button>
              </div>
            </div>
        </div>
    </div>
  </div>