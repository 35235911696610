import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartScales, ChartType } from 'chart.js';
import { Color, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-labels';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css']
})
export class BarChartComponent implements OnChanges {

  @Input('header') header = "";
  @Input('data') data = [];

  barChartOptions: ChartOptions;
  barChartLabels: Label[];
  barChartData: ChartDataSets[];
  barChartType: ChartType;
  barChartLegend: boolean;
  barChartPlugins = [];
  barChartColors: Color[];

  constructor() {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnChanges() {
    let d = [];
    let l = [];
    this.data.map((c, i) => {
      l.push(String(c.label));
      if (c.value == null) {
        d.push('0');
      } else {
        d.push(c.value);
      }
    });
    // d = [98, 8, 3]
    this.barChartLabels = l;
    this.barChartData = [{
      data: d,
      barPercentage: 0.9,
      categoryPercentage: 0.55,
      barThickness: 50,
    }];
    this.barChartType = 'bar';
    this.barChartLegend = false;
    this.barChartColors = [
      {
        backgroundColor: ['rgba(249,139,44,0.7)', 'rgba(228,52,8,0.7)', 'rgba(34,117,8,0.7)'],
      }
    ];
    this.barChartPlugins = [pluginDataLabels];
    this.barChartOptions = this.createOptions(d);
  }

  private createOptions(data): any {
    const stepSize = 5;
    const maxDataValue = Math.max(...data);
    const maxYAxisValue = Math.ceil(maxDataValue / stepSize) * stepSize + stepSize;

    return {
      responsive: true,
      tooltips: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem, data) {
            let count = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            return "Number of cases: " + count;
          },
        },
      },
      title: {
        display: true,
        text: 'Number of cases',
        position: 'left',
        fontSize: 14,
        padding: 0
      },
      scales: {
        xAxes: [{
          ticks: {
            padding: 0,
          },
          gridLines: {
            display: false,
            drawBorder: true,
          },
        }],
        yAxes: [{
          ticks: {
            display: false,
            beginAtZero: true,
            stepSize: stepSize,
            max: maxYAxisValue,
          },
          gridLines: {
            display: false,
            drawBorder: true,
          },
        }],
      },
      plugins: {
        datalabels: {
          display: false,
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontSize: 14,
            usePointStyle: true,
          },
        },
        labels: {
          render: "value",
          showZero: true,
          textMargin: 0,
          fontSize: 16,
          fontColor: [
            "rgba(249, 139, 44, 0.7)",
            "rgba(228, 52, 8, 0.7)",
            "rgba(34, 117, 8, 0.7)",
          ],
          // textShadow: true,
          // shadowBlur: 2,
          // shadowOffsetX: -2,
          // shadowOffsetY: 2,
        },
        
      },
    };
  }
  

}
