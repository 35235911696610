import { Component, Input } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets, LinearScale, LogarithmicScale, TimeScale } from 'chart.js';
import { Color, Label, monkeyPatchChartJsTooltip, monkeyPatchChartJsLegend } from 'ng2-charts';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})
export class LineChartComponent {

  @Input('header') header = "Line Chart";
  @Input('data') data = [];

  lineChartOptions: ChartOptions;
  lineChartLabels: Label[];
  lineChartData: ChartDataSets[];
  lineChartType: ChartType;
  lineChartLegend: boolean;
  lineChartColors: Color[];

  constructor() {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnChanges() {
    this.lineChartOptions = this.createOptions();
    let datasets = [];
    let l = [];
    this.data.map((dataset) => {
      l = dataset.data.map((point) => String(point.month));
      datasets.push({
        data: dataset.data.map((point) => point.count),
        label: dataset.label,
        borderColor: dataset.borderColor || 'rgba(0, 0, 0, 0.6)',
        fill: true,
        pointRadius: 5,
      });
    });
    this.lineChartLabels = l;
    this.lineChartData = datasets;
    this.lineChartType = 'line';
    this.lineChartLegend = true;
  }

  private createOptions(): ChartOptions {
    return {
      responsive: true,
      scales: {
        xAxes: [{ 
          display: true,
          type: 'category',
          gridLines: {
            display: true,
          },
        }],
        yAxes: [{
          display: true,
          ticks: {
            stepSize: 10,
          },
          gridLines: {
            display: true,
          },
        }],
      },
      tooltips: {
        enabled: true,
        callbacks: {
          title: (tooltipItems, data) => {
            if (tooltipItems && tooltipItems.length > 0) {
              const dataIndex = tooltipItems[0].index;
              const datasetIndex = tooltipItems[0].datasetIndex;
              const label = data.datasets[datasetIndex].label;
              const month = this.lineChartLabels[dataIndex];
              return `${month} - ${label}`;
            }
            return '';
          },
          label: function (tooltipItem, data) {
            let count = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            return `Number of cases: ${count}`;
          },
        },
      },
      maintainAspectRatio: true,
      legend: {
        display: false,
        position: 'right',
        fullWidth: true,
        labels: {
          fontSize: 12,
          padding: 5,
          usePointStyle: true
        }
      },
      plugins: {
        datalabels: {
          display: false,
          color: '#fff',
          align: 'center',
          anchor: 'center',
          font: {
            size: 14,
            weight: 'bold',
          }
        }
      },
    };
  }
}
