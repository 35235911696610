import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/common/global-constants';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthorizationService } from 'src/app/modules/authorization/authorization.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as forge from 'node-forge';
import { ToastService } from '../shared/service/toast.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  rsa = forge.pki.publicKeyFromPem(GlobalConstants.PublicKey);
  ForgetPassword_URI = GlobalConstants.ForgetPassword_URI + "?" + encodeURIComponent(GlobalConstants.UiBaseUrl);
  UpdatePassword_URI = GlobalConstants.UpdatePassword_URI + "?" + encodeURIComponent(GlobalConstants.UiBaseUrl);
  submitted = false;
  // loginForm: FormGroup
  selectedRole: number;
  count: string;
  page: string = 'login';
  isOtpSent: boolean = false;
  showPass: boolean = false;
  showCPass: boolean = false;
  showOPass: boolean = false;
  showFPass: boolean = false;
  showFCPass: boolean = false;
  StrongPasswordRegx: RegExp = /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;

  roles: any[] = [];

  ngOnInit(): void {

  }

  constructor(private formBuilder: FormBuilder,
    private AuthorizationService: AuthorizationService,
    public toastService: ToastService) {
  }

  loginForm = this.formBuilder.group({
    customerName: [null, [Validators.required,Validators.minLength(3)]],
    UserName: [null, [Validators.required, Validators.email]],
    Password: [null, [Validators.required]],
    customerRole: [null, Validators.required],
  });

  forgotPasswordForm = this.formBuilder.group({
    customerName: [null, [Validators.required, Validators.minLength(3)]],
    UserName: [null, [Validators.required, Validators.email]],
    Password: [null, [Validators.required, Validators.pattern(this.StrongPasswordRegx)]],
    ConfirmPassword: [null, [Validators.required]],
    OTP: [null, [Validators.required]],
  });

  updatePasswordForm = this.formBuilder.group({
    customerName: [null, [Validators.required, Validators.minLength(3)]],
    UserName: [null, [Validators.required, Validators.email]],
    OldPassword: [null, [Validators.required]],
    Password: [null, [Validators.required, Validators.pattern(this.StrongPasswordRegx)]],
    ConfirmPassword: [null, [Validators.required]],
  });

  get updatePasswordFormField() {
    return this.updatePasswordForm.get('Password');
  }

  get forgotPasswordFormField() {
    return this.forgotPasswordForm.get('Password');
  }

  // convenience getter for easy access to form fields
  get f() { 
    return this.loginForm.controls; 
  }

  get g() { 
    return this.forgotPasswordForm.controls; 
  }

  get u() {
    return this.updatePasswordForm.controls;
  }

  requestRolesList() {
    if (this.loginForm.controls.customerName.valid){
      
      var customerName = window.btoa(this.rsa.encrypt(this.loginForm.get('customerName').value));
      localStorage.setItem('current_tenant_name', this.loginForm.get('customerName').value);
      localStorage.setItem('current_tenant', customerName);
      localStorage.setItem('token', customerName);
       this.AuthorizationService.tenantRoles().subscribe((data: any) => {
         
        this.roles = data.data.result_records;
        this.roles.unshift({'role_id':0,'role_name':'Admin'});
      },
        (error: HttpErrorResponse) => {
          this.roles =[]
          this.loginForm.controls.customerRole.reset();
        });
    }
  }

  setCustomerName() {
    if (this.forgotPasswordForm.controls.customerName.valid){
      var customerName = window.btoa(this.rsa.encrypt(this.forgotPasswordForm.get('customerName').value));
      localStorage.setItem('current_tenant_name', this.forgotPasswordForm.get('customerName').value);
      localStorage.setItem('current_tenant', customerName);
      localStorage.setItem('token', customerName);
    }
  }

  setCustomerNameForUpdate() {
    if (this.updatePasswordForm.controls.customerName.valid){
      var customerName = window.btoa(this.rsa.encrypt(this.updatePasswordForm.get('customerName').value));
      localStorage.setItem('current_tenant_name', this.updatePasswordForm.get('customerName').value);
      localStorage.setItem('current_tenant', customerName);
      localStorage.setItem('token', customerName);
    }
  }

  showConfirm(content) {
    (document.getElementsByClassName('ng-placeholder'))[0].innerHTML = "Select Role";
    if (content>=0 && content != null){
      document.getElementsByClassName('ng-select-container')[0].className="ng-select-container";
      (document.getElementsByClassName('ng-placeholder'))[0].innerHTML = "";
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.controls.customerRole.invalid){
      // console.log(this.loginForm.get('customerRole').value)
      document.getElementsByClassName('ng-select-container')[0].className="ng-select-container ng-select-container_error";
    }else{
      if (this.submitted== true && this.loginForm.controls.customerRole.valid && this.loginForm.controls.UserName.valid &&  this.loginForm.controls.Password.valid){
        var UserData = {
          "username": this.loginForm.get('UserName').value,
          "role": this.loginForm.get('customerRole').value,
          "password": window.btoa(this.rsa.encrypt(this.loginForm.get('Password').value))
        };
        this.AuthorizationService.VerifyUser(UserData);
      }
    }

  }

  onSubmitPassword() {
    this.submitted = true;
    if (this.forgotPasswordForm.controls.customerName.valid && this.forgotPasswordForm.controls.OTP.valid && this.forgotPasswordForm.controls.UserName.valid &&  this.forgotPasswordForm.controls.Password.valid && this.forgotPasswordForm.controls.ConfirmPassword.valid){
      if (this.forgotPasswordForm.get('Password').value == this.forgotPasswordForm.get('ConfirmPassword').value) {
        var PasswordData = {
          "username": this.forgotPasswordForm.get('UserName').value,
          "password": window.btoa(this.rsa.encrypt(this.forgotPasswordForm.get('Password').value)),
          "otp": this.forgotPasswordForm.get('OTP').value,
        };
        this.AuthorizationService.ForgotPassword(PasswordData);
      } else {
        this.toastService.show('Passwords does not match', {
          classname: 'bg-error',
          delay: 5000,
          autohide: true
      });
      }
    }
  }

  onUpdatePassword() {
    this.submitted = true;
    if (this.updatePasswordForm.controls.customerName.valid && this.updatePasswordForm.controls.OldPassword.valid && this.updatePasswordForm.controls.UserName.valid &&  this.updatePasswordForm.controls.Password.valid && this.updatePasswordForm.controls.ConfirmPassword.valid){
      if (this.updatePasswordForm.get('Password').value == this.updatePasswordForm.get('ConfirmPassword').value) {
        var PasswordData = {
          "username": this.updatePasswordForm.get('UserName').value,
          "old_password": window.btoa(this.rsa.encrypt(this.updatePasswordForm.get('OldPassword').value)),
          "password": window.btoa(this.rsa.encrypt(this.updatePasswordForm.get('Password').value))
        };
        this.AuthorizationService.UpdatePassword(PasswordData);
      } else {
        this.toastService.show('Passwords does not match', {
          classname: 'bg-error',
          delay: 5000,
          autohide: true
      });
      }
    }
  }

  requestOTP() {
    if (this.forgotPasswordForm.controls.UserName.valid){
      var UserName = this.forgotPasswordForm.get('UserName').value;
      this.AuthorizationService.sendOTP(UserName).subscribe(
        (response: any) => {
            if (response?.msg) {
              this.isOtpSent = true;
              this.toastService.show(response?.msg, {
                classname: 'bg-success',
                delay: 5000,
                autohide: true
            });
            } else if (response?.error) {
              this.isOtpSent = false;
              this.toastService.show(response?.error, {
                classname: 'bg-error',
                delay: 5000,
                autohide: true
            });
            }
        },
        (error: any) => {
            this.toastService.show(error, {
                classname: 'bg-error',
                delay: 5000,
                autohide: true
            });
        }
    );
    }
  }

}
