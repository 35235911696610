<div class="card">
  <div class="card-header">
    <div class="card-title">
      <b>{{ header }}</b>
    </div>
  </div>
  <div class="card-body">
    <canvas
      baseChart
      [datasets]="barChartData" 
      [labels]="barChartLabels"
      [options]="barChartOptions"
      [colors]="barChartColors" 
      [plugins]="barChartPlugins" 
      [legend]="barChartLegend" 
      [chartType]="barChartType">
    </canvas>
  </div>
</div>
