<div class="card">
    <div class="card-header">
      <div class="card-title">
        <b>{{ header }}</b>
      </div>
    </div>
    <div class="card-body">
      <canvas
        baseChart
        [datasets]="lineChartData"
        [labels]="lineChartLabels"
        [options]="lineChartOptions"
        [colors]="lineChartColors"
        [legend]="lineChartLegend"
        [chartType]="lineChartType">
      </canvas>
    </div>
  </div>
  