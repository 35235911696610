import { Component, Input } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color, Label, monkeyPatchChartJsTooltip, monkeyPatchChartJsLegend } from 'ng2-charts';

@Component({
  selector: 'app-polar-area-chart',
  templateUrl: './polar-chart.component.html',
  styleUrls: ['./polar-chart.component.css']
})
export class PolarAreaChartComponent {

  @Input('header') header = "Polar Area Chart";
  @Input('data') data = [];

  polarAreaChartOptions: ChartOptions;
  polarAreaChartLabels: Label[];
  polarAreaChartData: ChartDataSets[];
  polarAreaChartType: ChartType;
  polarAreaChartLegend: boolean;
  polarAreaChartColors: Color[];

  constructor() {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnChanges() {
    this.polarAreaChartOptions = this.createOptions();
    let d = [];
    let l = [];
    this.data.map((c) => {
      l.push(String(c.label));
      if (c.value == null) {
        d.push('0');
      } else {
        d.push(c.value);
      }
    });
    this.polarAreaChartLabels = l;
    this.polarAreaChartData = [{ data: d, label: 'Data' }];
    this.polarAreaChartType = 'polarArea';
    this.polarAreaChartLegend = true;
    this.polarAreaChartColors = [
      {
        backgroundColor: [
          'rgba(34,117,8,0.6)',
          'rgba(228,52,8,0.6)',
          'rgba(52,20,196,0.6)',
          'rgba(249,139,44,0.6)',
          'rgba(32,201,151,0.6)'
        ],
      }
    ];
  }

  private createOptions(): ChartOptions {
    return {
      responsive: true,
      tooltips: {
        enabled: true,
        callbacks: {
          title: (tooltipItem) => {
            if (tooltipItem && tooltipItem.length > 0) {
              return `Company: ${this.data[tooltipItem[0].index].label}`;
            }
            return '';
          },
          label: (tooltipItem) => {
            if (tooltipItem) {
              const dataIndex = tooltipItem.index;
              const dataItem = this.data[dataIndex];
              const value = dataItem.value;
              return `Number of Cases: ${value}`;
            }
            return '';
          },
        },
      },
      scale: {
        angleLines: {
          display: true,
          color: 'rgba(0, 0, 0, 0.1)',
        },
        ticks: {
          beginAtZero: true,
          stepSize: 10,
        },
      },
      elements: {
        arc: {
          borderWidth: 0,
        },
      },
      plugins: {
        datalabels: {
          anchor: 'center',
          align: 'center',
          color: '#fff',
          font: {
            size: 14,
            weight: 'bold',
          },
        },
        labels: {
          render: 'value', // 'label', 'percentage', 'value', 'image'
          fontColor: '#fff',
          fontSize: 14,
          position: 'default', //'default', 'border' and 'outside'
        }
      },
      maintainAspectRatio: true,
      legend: {
        position: 'right',
        fullWidth: true,
        labels: {
          fontSize: 12,
          padding: 5,
          usePointStyle: true
        }
      },
    };
  }
}
