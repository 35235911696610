<div class="card">
    <div class="card-header">
      <div class="card-title">
        <b>{{ header }}</b>
      </div>
    </div>
    <div class="card-body">
      <canvas baseChart
        [datasets]="radarChartData"
        [labels]="radarChartLabels"
        [chartType]="radarChartType"
        [options]="radarChartOptions"
        [colors]="radarChartColors"
        [legend]="radarChartLegend">
      </canvas>
    </div>
  </div>
  