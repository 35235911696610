import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Color, Label, SingleDataSet, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-labels';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css']
})
export class PieChartComponent implements OnChanges {

  @Input('header') header = "Pie Chart";
  @Input('data') data = [];

  pieChartOptions: ChartOptions;
  pieChartLabels: Label[];
  pieChartData: SingleDataSet;
  pieChartType: ChartType;
  pieChartLegend: boolean;
  pieChartPlugins = [];
  pieChartColors: Color[];


  constructor() {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnChanges() {
    this.pieChartOptions = this.createOptions();
    let d = [];
    let l = [];
    this.data.map((c) => {
      l.push(String(c.label));
      if (c.value == null) {
        d.push('0');
      } else {
        d.push(c.value);
      }
    });
    this.pieChartLabels = l;
    this.pieChartData = d;
    this.pieChartType = 'doughnut';
    this.pieChartLegend = true;
    this.pieChartPlugins = [pluginDataLabels];
    this.pieChartColors = [
      {
        backgroundColor: [
          'rgba(34,117,8,0.6)',
          'rgba(228,52,8,0.6)', 
          'rgba(52,20,196,0.6)', 
          'rgba(249,139,44,0.6)',
          'rgba(32,201,151,0.6)'
        ],
      }
    ];
  }

  private createOptions(): ChartOptions {
    return {
      responsive: true,
      tooltips: {
        enabled: true,
        callbacks: {
          title: (tooltipItem) => {
            if (tooltipItem && tooltipItem.length > 0) {
              return `Wrokflow: ${this.data[tooltipItem[0].index].label}`;
            }
            return '';
          },
          label: (tooltipItem) => {
            if (tooltipItem) {
              const dataIndex = tooltipItem.index;
              const dataItem = this.data[dataIndex];
              const value = dataItem.value;
              return `Number of Cases: ${value}`;
            }
            return '';
          },
        },
      },
      maintainAspectRatio: true,
      cutoutPercentage: 40,
      legend: { 
        position: 'right',
        fullWidth: true,
        labels: {
          fontSize: 12,
          padding: 5,
          usePointStyle: true
        }
      },
      plugins: {
        datalabels: {
          display: false,
        },
        labels: {
          render: 'value', // 'label', 'percentage', 'value', 'image'
          fontColor: '#fff',
          fontSize: 14,
          // showZero: true,
          // textShadow: true,
          // shadowBlur: 2,
          // shadowOffsetX: -5,
          // shadowOffsetY: 5,
          // shadowColor: 'rgba(0,0,0,0.3)',
          position: 'default', //'default', 'border' and 'outside'
        }
      },
    };
  }

}
