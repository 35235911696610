import { Component } from '@angular/core';
import { HostListener } from "@angular/core";
import { Router, ActivatedRoute, Params, RoutesRecognized } from '@angular/router';
import { GlobalConstants } from 'src/app/common/global-constants';
import { Location } from '@angular/common';
import { AuthorizationService } from 'src/app/modules/authorization/authorization.service';

@Component({
  selector: 'app-authorization',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.css']
})
export class AuthorizationComponent {

  title = 'Salvus';
  screenWidth: number;
  isMenuOpen = false;
  contentWidth = 220;
  main_content = 0;
  contentMarginLeft: number;
  contentVisiablity: string;
  contentOverflow: string;
  // contentDisplay: string;
  leftMobile: string;
  authReturn: any;
  iconId = 0;
  role: string = "";
  currentUser: string = "";
  modules = [];
  SessionValue: string = "";
  route: any;
  parent_url: any;
  child_url: any;
  param_id: string = '';
  id_token = '';
  current_role: string;
  navInMobile: boolean;
  name: string = '';
  // app_version = '';

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private AuthorizationService: AuthorizationService) {
    this.screenWidth = window.innerWidth;
    this.contentMarginLeft = -30;
    this.contentVisiablity = 'unset';
    this.contentOverflow = 'hidden';
    this.leftMobile = "0%";
    if (this.screenWidth < 769) {
      this.navInMobile = false;
    } else {
      this.navInMobile = true;
    }
    this.getScreenSize();
    this.SessionValue = AuthorizationService.currentUserValue
    if (this.SessionValue != "") {
      this.currentUser = this.SessionValue[0]['user_email']
      this.name = this.SessionValue[0]['first_name'] + ' ' + this.SessionValue[0]['last_name']
      this.current_role = AuthorizationService.currentUserRole
      if (parseInt(this.current_role) == 0) {
        this.role = 'Admin'
      } else {
        this.current_role = ""
        var matched_role
        const user_has_roles = this.SessionValue[0]['user_has_roles']
        user_has_roles.forEach(function (value) {
          if (parseInt(AuthorizationService.currentUserRole) == parseInt(value['role']['role_id'])) {

            matched_role = value['role']['role_name']
          }
        });
        this.role = matched_role
      }
    }
  }

  ngOnInit(): void { }

  isAuthenticated(module: string, action: string) {
    return this.AuthorizationService.isAuthenticated(module, action);
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 769) {
      this.contentWidth = 220;
      this.contentMarginLeft = -30;
      this.main_content = 0;
      this.contentVisiablity = 'unset';
      this.contentOverflow = 'hidden';
    } else {
      if (!this.isMenuOpen) {
        this.contentWidth = 60;
        this.contentMarginLeft = -190;
        this.main_content = -160;
        this.contentVisiablity = 'collapse';
        this.contentOverflow = 'unset';
      } else {
        this.contentWidth = 220;
        this.contentMarginLeft = -30;
        this.main_content = 0;
        this.contentVisiablity = 'unset';
        this.contentOverflow = 'hidden';
      }
    }
  }


  onToolbarMenuToggle() {
    if (this.screenWidth >= 769) {
      this.navInMobile = true;
      this.isMenuOpen = !this.isMenuOpen;
      if (!this.isMenuOpen) {
        this.contentWidth = 60;
        this.contentMarginLeft = -190;
        this.main_content = -160;
        this.contentVisiablity = 'collapse';
        this.contentOverflow = 'unset';
      } else {
        this.contentWidth = 220;
        this.contentMarginLeft = -30;
        this.main_content = 0;
        this.contentVisiablity = 'unset';
        this.contentOverflow = 'hidden';
      }
    } else {
      this.navInMobile = !this.navInMobile;
    }
  }

  navChange() {
    if (this.screenWidth < 769) {
      this.navInMobile = false;
    }
  }

  rotate(event: any, id: string) {
    var element = document.getElementById(id);
    if (element != null) {
      if (element.classList.contains('rotate')) {
        element.classList.remove('rotate');
      } else {
        element.classList.add('rotate');
      }
    }


    // if(event.target.classList.contains('rotate')){
    //   event.srcElement.classList.remove("rotate");
    // }else{
    //   event.srcElement.classList.add("rotate");
    // }
  }

  changeStyle(event: any, _id: number) {
    if (this.screenWidth >= 769) {
      if (!this.isMenuOpen && event.type == "mouseover") {
        this.iconId = _id;
      } else if (!this.isMenuOpen && event.type == "mouseout") {
        this.iconId = 0;
      }
    }
  }

  onToolbarMenuToggleMobile(event: any) {
    if (this.screenWidth < 769) {
      this.isMenuOpen = !this.isMenuOpen;
      this.leftMobile = "0%";
    }
  }

  LogOut() {
    const endPoint = GlobalConstants.UiBaseUrl;
    localStorage.clear();
    window.location.href = endPoint;
  }
}
