<div class="card">
  <div class="card-header">
    <div class="card-title">
      <b>{{ header }}</b>
    </div>
  </div>
  <div class="card-body">
    <canvas baseChart
      [data]="pieChartData"
      [labels]="pieChartLabels"
      [chartType]="pieChartType"
      [options]="pieChartOptions"
      [colors]="pieChartColors"
      [plugins]="pieChartPlugins"
      [legend]="pieChartLegend">
    </canvas>
  </div>
</div>
