import { Component, Input, OnInit } from '@angular/core';
import { ConfirmDialogService } from '../../service/confirm-dialog.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
  message: any;
  constructor(private ConfirmDialogService: ConfirmDialogService) { }

  ngOnInit(): any {
    this.ConfirmDialogService.getMessage().subscribe(message => {
      this.message = message;
    });
  }

}
