<div class="row col-md-6 mr-auto" style="margin-left: -20px;">
    <div class="col-md-11 mb-2">
        <input type="text" [(ngModel)]="term" class="form-control" 
        placeholder="Search Case Number" maxlength="15" />
    </div>
    <div class="col-md-1 mb-2">
        <button (click)="onSearch()" class="btn btn-primary custom_btn mr-3" style="background-color: #007bff !important;">Open</button>
        <button style="display: none;" id="auto_trigger" (click)="showDialog($event,searchId)"></button>
    </div>
</div>
<div class="card">
    <div class="card-body pb-0">
        <div class="row">
            <div class="col-md-4 mb-4">
                <app-pie-chart [header]="'Workflow Case Count'" [data]="pieChartData"></app-pie-chart>
            </div>
            <div class="col-md-4 mb-4">
                <app-bubble-chart [header]="'Report Type Case Count'" [data]="reportTypeData"></app-bubble-chart>
            </div>
            <div class="col-md-4 mb-4">
                <app-bar-chart [header]="'Seriousness Case Count'" [data]="barChartData"></app-bar-chart>
            </div>
            <div class="col-md-4 mb-4">
                <app-polar-area-chart [header]="'Company Case Count'" [data]="companyData"></app-polar-area-chart>
            </div>
            <div class="col-md-4 mb-4">
                <app-radar-chart [header]="'Case Lock Count'" [data]="lockData"></app-radar-chart>
            </div>
            <div class="col-md-4 mb-4">
                <app-line-chart [header]="'Monthly Case Count'" [data]="monthlyData"></app-line-chart>
            </div>
        </div>
    </div>
</div>
<app-confirm-dialog></app-confirm-dialog>