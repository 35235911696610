<div class="card">
    <div class="card-header">
      <div class="card-title">
        <b>{{ header }}</b>
      </div>
    </div>
    <div class="card-body">
      <canvas baseChart
        [datasets]="polarAreaChartData"
        [labels]="polarAreaChartLabels"
        [chartType]="polarAreaChartType"
        [options]="polarAreaChartOptions"
        [legend]="polarAreaChartLegend"
        [colors]="polarAreaChartColors">
      </canvas>
    </div>
  </div>
  