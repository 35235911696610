import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { DashboardService } from '../../services/dashboard.service';
import { ToastService } from 'src/app/modules/shared/service/toast.service';  
import { ConfirmDialogService } from '../../../shared/service/confirm-dialog.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  term: string;
  dashboardCount: Observable<any[]>;
  barLegendColors = ['rgba(249,139,44,0.7)', 'rgba(228,52,8,0.7)', 'rgba(34,117,8,0.7)'];
  pieChartData = [];
  barChartData = [];
  companyData = [];
  reportTypeData = [];
  monthlyData = [];
  lockData = [];
  currentRole: string = localStorage.getItem('current_role');
  searchId= "";

  constructor(
    private dashboardService: DashboardService,
    public toastService: ToastService,
    private router: Router,
    private ConfirmDialogService: ConfirmDialogService,
  ) {}
  
  ngOnInit(): void {
    this.dashboardService.loadDashboardCounts();
    this.dashboardCount = this.dashboardService.dashboardCounts;
    this.dashboardCount.subscribe((count) => {
      count.map((c) => {
        this.pieChartData = c.pie;
        this.barChartData = c.bar;
        this.companyData = c.company;
        this.reportTypeData = c.report_type;
        this.monthlyData = c.monthly;
        this.lockData = c.lock;
      });
    });
  }

  onSearch() {
    if (this.term == "" || this.term == undefined) {
      this.toastService.show("Please enter valid Case number", {
        classname: 'bg-error',
        delay: 500,
        autohide: true
      });
    } else {
      this.dashboardService.searchCase(this.term).subscribe((result) => {
        const data = result['data'];
        if (data.length > 0) {          
          let current_activity_access_role = data[0]['current_activity_access_role'];
          let case_id = data[0]['case_id_pk'];
          let case_no = data[0]['case_no'];
          let is_locked = data[0]['is_locked'];
          this.searchId = case_id;
          
          if(this.currentRole == '0'){
            this.router.navigate(['/case/edit-case', case_id]);
          }else{
            if((current_activity_access_role != null && ((current_activity_access_role)?.split(',')).indexOf(this.currentRole) > -1)){
              this.router.navigate(['/case/edit-case', case_id]);
            }else{
              let element:HTMLElement = document.getElementById('auto_trigger') as HTMLElement;
              element.click();
            }
          }
          // this.router.navigate(['/case/edit', case_id]);
        } else {
          this.toastService.show("Please enter valid Case number", {
            classname: 'bg-error',
            delay: 500,
            autohide: true
          });
        }
      });
    }
  }

  showDialog(event: Event, id) {
    if(id != "" && id!= 0){
      this.ConfirmDialogService.confirmThis("You do not have permission to update this case data.\r\n Do you want to view this case?", () => {
        this.router.navigate(['/case/view-case', id]);
      }, function () { });
    }
  }

}
